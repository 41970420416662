import { IssueType } from "@medbillai/graphql-types";

type IssueConfig = {
  title: string;
  message: string;
  initialMessage: string;
  ctaText: string;
};

export const issueConfig = (issueType?: IssueType): IssueConfig => {
  switch (issueType) {
    case IssueType.DOCTOR_VISIT:
      return {
        title: "Planning for a doctor visit?",
        message:
          "We will find doctors that are in-network and we will get you a cost estimate for your visit.",
        initialMessage:
          "Please tell us about your doctor visit. We are here to help you find the best provider in your network.",
        ctaText: "Find a doctor",
      };
    case IssueType.MEDICAL_BILL:
      return {
        title: "Get help with a medical bill or claim",
        message:
          "Our expert team can review any medical bills or claims using AI. If there are any issues, we will take care of them. Easy!",
        initialMessage:
          "Sorry to hear you are facing an issue with a bill. Please describe the problem or upload any relevant documents, and we’ll take it from there.",
        ctaText: "Lower your bill",
      };
    case IssueType.OPEN_ENROLLMENT:
      return {
        title: "Get help during open enrollment",
        message:
          "Confused about which plans to pick and how much to contribute to your HSA/FSA? We will review all your options and tell you which is best for your family.",
        initialMessage:
          "Open enrollment can be daunting. Let us know your questions or concerns and we will help you navigate your choices.",
        ctaText: "Optimize benefits",
      };
    case IssueType.BILL_DISPUTE:
      return {
        title: "Dispute a bill",
        message:
          "If you have received a bill that you believe is incorrect, we can help you dispute it.",
        initialMessage:
          "Please describe the issue you are facing with the bill and attach any relevant documents.",
        ctaText: "Dispute a bill",
      };
    case IssueType.OTHER:
      return {
        title: "Something else?",
        message:
          "For general questions regarding your healthcare coverage, we will get back to you within 24 hours.",
        initialMessage:
          "How can we help you today? Please describe your issue and attach any relevant documents.",
        ctaText: "Message us",
      };
    case IssueType.ONBOARDING:
      return {
        title: "Welcome to Medbill AI",
        message:
          "For general questions regarding your healthcare coverage, we will get back to you within 24 hours.",
        initialMessage:
          "Welcome to Medbill AI! Our team of experts is here to resolve all your medical billing or health insurance issues. No question or issue is too big or too small. How can we help?",
        ctaText: "Welcome",
      };
    case IssueType.UNKNOWN:
    default:
      return {
        title: "How can we help you today?",
        message:
          "Please add documents and any context information that you think will be useful. We will get back to you as soon as possible.",
        initialMessage:
          "How can we help you today? Please describe your issue and attach any relevant documents.",
        ctaText: "",
      };
  }
};
