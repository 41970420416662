import React from "react";

import { IssueType } from "@medbillai/graphql-types";

import { YStack } from "@medbillai/ui";

import { issueConfig } from "@medbillai/utils/issues";

import { ScreenView, type ScreenViewProps } from "../../components/ScreenView";
import { CreateCase } from "./components/CreateCase";

export type HomeScreenProps = {
  screenViewProps?: Omit<ScreenViewProps, "children">;
};

export function HomeScreen({ screenViewProps }: HomeScreenProps) {
  return (
    <ScreenView stackProps={{ padding: "$4" }} {...screenViewProps}>
      <YStack gap="$4.5">
        {[
          IssueType.MEDICAL_BILL,
          IssueType.OPEN_ENROLLMENT,
          IssueType.DOCTOR_VISIT,
          IssueType.OTHER,
        ].map(issueType => {
          const config = issueConfig(issueType);
          return (
            <CreateCase
              key={issueType}
              title={config.title}
              message={config.message}
              ctaText={config.ctaText}
              issueType={issueType}
            />
          );
        })}
      </YStack>
    </ScreenView>
  );
}
