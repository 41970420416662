import React from "react";
import { useRouter } from "solito/router";

import { type IssueType } from "@medbillai/graphql-types";

import { H6, LoadingButton, Paragraph, YBlock, YStack } from "@medbillai/ui";

import { issuePreviewUrl } from "@medbillai/utils/urls";

type CreateCaseProps = {
  title: string;
  message: string;
  ctaText: string;
  issueType: IssueType;
};

export const CreateCase = ({
  title,
  message,
  ctaText,
  issueType,
}: CreateCaseProps) => {
  const { push } = useRouter();

  const previewIssue = () => {
    push(issuePreviewUrl(issueType));
  };

  return (
    <YBlock
      bc="$color1"
      px="$5"
      py="$4.5"
      gap="$3"
      shadowColor="$purple4"
      shadowRadius="$2"
      borderWidth={0.5}
      borderColor="$color5"
    >
      <H6>{title}</H6>
      <Paragraph theme="alt2" size="$3">
        {message}
      </Paragraph>
      <YStack mt="$2" ai="flex-start">
        <LoadingButton
          loading={false}
          onPress={previewIssue}
          height="$3.5"
          width="50%"
          stickyLoading
        >
          {ctaText}
        </LoadingButton>
      </YStack>
    </YBlock>
  );
};
